import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import styled from '@emotion/styled';

import Layout from '../components/layout';

const Wrapper = styled(Container)`
  padding-bottom: 15rem !important;
`;
const SnaggyLabs = () => (
  <Layout>
    <Helmet title={'Snaggy Labs | Privacy Policy'} />
    <Wrapper>
      <h3 id="repodev">Privacy Policy</h3>

      <p>
        Hello! Welcome to the Snaggy Labs Privacy Policy. This policy describes
        how advertising partners collect, use, process and share information
        about you.
      </p>

      <p>
        This Privacy Policy applies to all of our apps. It also applies to our
        Advertising activities on all platforms that we may provide to you from
        time to time.
      </p>

      <p>
        By downloading, accessing and/or using our apps, you agree to the
        collection and use of your information in accordance with this Privacy
        Policy, including our advertising partners’ use of your data for
        targeted advertising purposes. Most of this data or information comes
        directly from you or your device, such as device information, country
        and region. We use this data to provide our apps to you, including for
        the purposes of optimisation. We may also use it for the other purposes
        set out in this Privacy Policy, including for Advertising purposes. If
        you have any other concerns about providing information to us or it
        being used as described in this Privacy Policy you should not use our
        apps.
      </p>

      <p>
        Our apps may include advertising for third party products and services.
        Our advertising partners collect and use information about you to
        improve advertising, targeting and measurement systems so that we can
        show you relevant third party ads in our apps.
      </p>

      <p>
        You have certain rights in connection with your personal information and
        how we handle it. You can exercise these rights at any time by
        contacting us via Email.
      </p>

      <p>
        You must be over a certain age to use our apps, depending on where you
        live. We do not knowingly collect or solicit personal information from
        or direct or target interest based advertising to anyone under the
        certain ages. If you believe that we might have any information from or
        about a child under the certain age, please Contact Us.
      </p>

      <p>
        If you have any questions or concerns about any of the matters in
        relation to this Privacy Policy, including to exercise any of your
        rights, then you can contact us via email to snag.labs@gmail.com.
      </p>

      <p>
        <em>This Privacy Policy was last updated on 4.23.2021.</em>
      </p>
    </Wrapper>
  </Layout>
);

export default SnaggyLabs;
